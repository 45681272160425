// Generated by Framer (f7d95e4)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const enabledGestures = {g25vAJMRf: {hover: true}};

const serializationHash = "framer-18Iho"

const variantClassNames = {g25vAJMRf: "framer-v-1lay0ox"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "g25vAJMRf", enabledGestures, ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={"https://www.handelszeitung.ch/podcasts/upbeat/jurata-dieses-startup-vermittelt-anwalte-per-mausklick-381360"} motionChild nodeId={"g25vAJMRf"} scopeId={"wRURE2ibn"}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", intrinsicHeight: 11.5, intrinsicWidth: 160, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 23, pixelWidth: 320, src: "https://framerusercontent.com/images/jlAz3ctbFsHMessYvqhe6w2eOc.webp"}} className={`${cx(scopingClassNames, "framer-1lay0ox", className, classNames)} framer-1q0snq8`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"g25vAJMRf"} ref={refBinding} style={{filter: "grayscale(1) saturate(0)", WebkitFilter: "grayscale(1) saturate(0)", ...style}} variants={{"g25vAJMRf-hover": {filter: "grayscale(0) saturate(0)", WebkitFilter: "grayscale(0) saturate(0)"}}} {...addPropertyOverrides({"g25vAJMRf-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}/></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-18Iho.framer-1q0snq8, .framer-18Iho .framer-1q0snq8 { display: block; }", ".framer-18Iho.framer-1lay0ox { align-content: center; align-items: center; cursor: default; display: flex; flex-direction: row; flex-wrap: nowrap; height: 23px; justify-content: space-around; overflow: visible; padding: 0px; position: relative; text-decoration: none; width: 354px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 23
 * @framerIntrinsicWidth 354
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"t0sRrI9AI":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerwRURE2ibn: React.ComponentType<Props> = withCSS(Component, css, "framer-18Iho") as typeof Component;
export default FramerwRURE2ibn;

FramerwRURE2ibn.displayName = "Logo 1";

FramerwRURE2ibn.defaultProps = {height: 23, width: 354};

addFonts(FramerwRURE2ibn, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})